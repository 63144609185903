import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import CourseSideCetagory from "../component/sidebar/course-cetagory";
import CourseSideDetail from "../component/sidebar/course-detail";
import Respond from "../component/sidebar/respond";
import { useParams } from "react-router-dom";
import { courseList } from "../assets/data/courseList";

const CourseSingle = () => {
  const params = useParams();
  const content = courseList.filter(
    (item) => item.courseName == params.courseName
  );
  return (
    <Fragment>
      <Header />
      <PageHeaderTwo
        courseDescription={content[0].courseDescription.toString()}
        courseInstructor={content[0].authorImgUrl}
        courseAuthor={content[0].authorName}
        courseImage={content[0].imgUrl}
        courseTitle={content[0].courseTitle}
      />
      <div className="course-single-section padding-tb section-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="main-part">
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-content">
                      <h3>Përshkrimi i Trajnimit</h3>
                      <p>{content[0].courseOverview}</p>
                      <h4>Çfarë Do Të Mësoni Në Këtë Trajnim:</h4>
                      <ul className="lab-ul">
                        {content[0].courseLearningPoints.map((item) => (
                          <li>
                            <i className="icofont-tick-mark"></i>
                            {item}
                          </li>
                        ))}
                      </ul>
                      {/* <p>{content[0].courseMidExplanation}</p> */}
                      <p>{content[0].courseEndExplanation}</p>
                    </div>
                  </div>
                </div>

                <div className="course-video">
                  <div className="course-video-title">
                    <h4>Planprogrami i Trajnimit</h4>
                  </div>

                  <div className="course-video-content">
                    <div className="accordion" id="accordionExample">
                      {content[0]?.levels?.levelsContent.map((item) => {
                        return (
                          <div className="accordion-item">
                            <div className="accordion-header" id="accordion01">
                              <button
                                className="d-flex flex-wrap justify-content-between"
                                data-bs-toggle="collapse"
                                data-bs-target="#videolist1"
                                aria-expanded="true"
                                aria-controls="videolist1"
                              >
                                <span>{item.levelTitle}</span>{" "}
                                <span>{item.levelTime}</span>{" "}
                              </button>
                            </div>
                            <div
                              id="videolist1"
                              className="accordion-collapse collapse show"
                              aria-labelledby="accordion01"
                              data-bs-parent="#accordionExample"
                            >
                              <ul className="lab-ul video-item-list">
                                <li className=" d-flex flex-wrap justify-content-between">
                                  <div className="video-item-title">
                                    {item.levelDescription}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        );
                      })}

                      {/* <div className="accordion-item">
                        <div className="accordion-header" id="accordion02">
                          <button
                            className="d-flex flex-wrap justify-content-between"
                            data-bs-toggle="collapse"
                            data-bs-target="#videolist2"
                            aria-expanded="true"
                            aria-controls="videolist2"
                          >
                            {" "}
                            <span>
                              2.How to Create Mixed Media Art in Adobe Photoshop
                            </span>{" "}
                            <span>5 lessons, 52:15</span>{" "}
                          </button>
                        </div>
                        <div
                          id="videolist2"
                          className="accordion-collapse collapse"
                          aria-labelledby="accordion02"
                          data-bs-parent="#accordionExample"
                        >
                          <ul className="lab-ul video-item-list">
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.1 Using Adjustment Layers 06:20 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.2 Building the composition 07:33 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.3 Photoshop Lighting effects 06:30 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.4 Digital Painting using photoshop brushes
                                08:34 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.5 Finalizing the details 10:30 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <Author
                  authorName={content[0].authorName}
                  authorLinkedIn={content[0].linkedIn}
                  authorDescription={content[0].authorDescription}
                  authorImgUrl={content[0].authorImgUrl}
                />
                {/* <Comment /> */}
                {/* <Respond /> */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-part">
                <CourseSideDetail
                  coursePrice={content[0].coursePrice}
                  courseLevels={content[0].courseLevels}
                  courseDuration={content[0].courseTime}
                  monthlyPrice={content[0].monthlyPrice}
                  recommendedAge={content[0].recommendedAge}
                  courseName={content[0].courseName}
                />
                {/* <CourseSideCetagory /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default CourseSingle;
