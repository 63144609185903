import Rating from '../sidebar/rating';
import { useState } from 'react';

const subTitle = 'Instruktorë të kualifikuar';
// const title = "";

const instructorList = [
  {
    id: 1,
    imgUrl1: 'assets/images/instructor/skender.jpg',
    imgUrl2: 'assets/images/instructor/skender_hover.jpg',
    imgAlt: 'instructor',
    name: 'Skender Mustafa',
    degi: 'Software Engineer',
    courseCount: '08 courses',
    studentAnroll: '30 students',
  },
  {
    id: 2,
    imgUrl1: 'assets/images/instructor/agron.jpg',
    imgUrl2: 'assets/images/instructor/agron_hover.jpg',
    imgAlt: 'instructor',
    name: 'Agron Bajrami',
    degi: 'Software Engineer',
    courseCount: '08 courses',
    studentAnroll: '30 students',
  },
  {
    id: 3,
    imgUrl1: 'assets/images/instructor/jeton.jpg',
    imgUrl2: 'assets/images/instructor/jeton_hover.jpg',
    imgAlt: 'jeton ramadani',
    name: 'Jeton Ramadani',
    degi: 'Software Engineer',
    courseCount: '08 courses',
    studentAnroll: '30 students',
  },

  {
    id: 4,
    imgUrl1: 'assets/images/instructor/flamur.jpg',
    imgUrl2: 'assets/images/instructor/flamur_hover.jpg',
    imgAlt: 'instructor',
    name: 'Flamur Bakiu',
    degi: 'Software Engineer',
    courseCount: '08 courses',
    studentAnroll: '30 students',
  },
  {
    id: 5,
    imgUrl1: 'assets/images/instructor/edmond-1.jpg',
    imgUrl2: 'assets/images/instructor/edmond-2.jpg',
    imgAlt: 'instructor',
    name: 'Dr. Edmond Jajaga',
    degi: 'Software Engineer, Researcher and Lecturer',
    courseCount: '08 courses',
    studentAnroll: '30 students',
  },
  {
    id: 6,
    imgUrl1: 'assets/images/instructor/rezon-1.jpg',
    imgUrl2: 'assets/images/instructor/rezon-2.jpg',
    imgAlt: 'instructor',
    name: 'Rezon Karpuzi',
    degi: 'User Experience & Interface Designer',
    courseCount: '08 courses',
    studentAnroll: '30 students',
  },
  {
    id: 7,
    imgUrl1: 'assets/images/instructor/profile-icon.png',
    imgUrl2: 'assets/images/instructor/profile-icon.png',
    imgAlt: 'instructor',
    name: 'Esra Dura Mustafa',
    degi: 'Software Engineer',
    courseCount: '08 courses',
    studentAnroll: '30 students',
  },
  {
    id: 8,
    imgUrl1: 'assets/images/instructor/profile-icon.png',
    imgUrl2: 'assets/images/instructor/profile-icon.png',
    imgAlt: 'instructor',
    name: 'Naile Toçi Limani',
    degi: 'Software Engineer',
    courseCount: '08 courses',
    studentAnroll: '30 students',
  }
  // {
  //   id: 7,
  //   imgUrl1: 'assets/images/instructor/reshat.jpg',
  //   imgUrl2: 'assets/images/instructor/reshat_hover.jpg',
  //   imgAlt: 'instructor',
  //   name: 'Reshat Ameti',
  //   degi: 'Mësimdhënës i grupit klasor',
  //   courseCount: '08 courses',
  //   studentAnroll: '30 students',
  // },
];

const Instructor = ({ isHome }) => {
  const [hoveredItemId, setHoveredItemId] = useState(null);

  const handleMouseOver = (itemId) => {
    setHoveredItemId(itemId);
  };

  const handleMouseOut = () => {
    setHoveredItemId(null);
  };

  return (
    <div className='instructor-section padding-tb section-bg'>
      <div className='container'>
        <div className='section-header text-center'>
          <span className='subtitle'>{subTitle}</span>
          {/* <h2 className="title">{title}</h2> */}
        </div>
        <div className='section-wrapper'>
          <div className='row g-4 justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4'>
            {instructorList.slice(0, isHome ? 4 : instructorList.length).map((val, i) => (
              <div className='col' key={i}>
                <div className='instructor-item'>
                  <div className='instructor-inner'>
                    <div
                      className='instructor-thumb'
                      onMouseOver={() => handleMouseOver(val.id)}
                      onMouseOut={handleMouseOut}
                    >
                      <img
                        src={
                          hoveredItemId === val.id ? val.imgUrl2 : val.imgUrl1
                        }
                        width='220'
                        alt={`${val.imgAlt}`}
                      />
                    </div>
                    <div className='instructor-content'>
                      {/* <Link to="/team-single"> */}
                      <h4>{val.name}</h4>
                      {/* </Link> */}
                      <p>{val.degi}</p>
                      {/* <Rating /> */}
                    </div>
                  </div>
                  {/* <div className="instructor-footer">
                                        <ul className="lab-ul d-flex flex-wrap justify-content-between align-items-center">
                                            <li><i className="icofont-book-alt"></i> {val.courseCount}</li>
                                            <li><i className="icofont-users-alt-3"></i> {val.studentAnroll}</li>
                                        </ul>
                                    </div> */}
                </div>
              </div>
            ))}
          </div>
          {/* <div className="text-center footer-btn">
                        <p>Want to help people learn, grow and achieve more in life?<Link to="/team">Become an instructor</Link></p>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default Instructor;
