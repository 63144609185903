const subTitle = '';
const title = (
  <h2 className='title'>
    Give us the <span style={{ color: '#26b571' }}>pieces,</span> we build the{' '}
    <span style={{ color: '#26b571' }}>puzzle!</span>
  </h2>
);
const description = (
  <p className='desc'>
    Unleash your potential, shape your future, <br />
    empower problem-solving. Join us on the road to success and discover
    limitless possibilities.
  </p>
);

const catagoryList = [
  {
    name: 'Figma',
    link: '#',
  },
  {
    name: 'Adobe XD',
    link: '#',
  },
  {
    name: 'illustration',
    link: '#',
  },
  {
    name: 'Photoshop',
    link: '#',
  },
];

const shapeList = [
  {
    name: '150+ nxënës të kënaqur',
    link: '#',
    className: 'ccl-shape shape-1',
  },
  {
    name: 'Inxhinieri Softuerike',
    link: '#',
    className: 'ccl-shape shape-2',
  },
  {
    name: 'Front-end zhvillues',
    link: '#',
    className: 'ccl-shape shape-3',
  },
  {
    name: 'Mobile app zhvillues',
    link: '#',
    className: 'ccl-shape shape-4',
  },
  // {
  //   name: 'Shkenca ekzakte',
  //   link: '#',
  //   className: 'ccl-shape shape-5',
  // },
  {
    name: 'UI/UX Design',
    link: '#',
    className: 'ccl-shape shape-5',
  },
  // {
  //     name: 'Gjuhë të huaja',
  //     link: '#',
  //     className: 'ccl-shape shape-5',
  // },
];

const Banner = () => {
  return (
    <section className='banner-section'>
      <div className='container'>
        <div className='section-wrapper'>
          <div className='row align-items-center'>
            <div className='col-xxl-5 col-xl-6 col-lg-10'>
              <div className='banner-content'>
                {/* <h6 className="subtitle text-uppercase fw-medium">{subTitle}</h6> */}
                {title}
                {description}
                {/* <form action="/">
                                    <div className="banner-icon">
                                        <i className="icofont-search"></i>
                                    </div>
                                    <input type="text" placeholder="Keywords of your course" />
                                    <button type="submit">Search Course</button>
                                </form> */}
                {/* <div className="banner-catagory d-flex flex-wrap">
                                    <p>Most Popular : </p>
                                    <ul className="lab-ul d-flex flex-wrap">
                                        {catagoryList.map((val, i) => (
                                            <li key={i}><a href={val.link}>{val.name}</a></li>
                                        ))}
                                    </ul>
                                </div> */}
              </div>
            </div>
            <div className='col-xxl-7 col-xl-6'>
              <div className='banner-thumb'>
                <img src='assets/images/banner/man1.png' alt='img' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='all-shapes'></div>
      <div className='cbs-content-list d-none'>
        <ul className='lab-ul'>
          {shapeList.map((val, i) => (
            <li className={val.className} key={i}>
              <a href={val.link}>{val.name}</a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Banner;
